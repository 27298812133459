// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {DefaultComponent} from "../app/payment/default.component";
import {WithRefundComponent} from "../app/payment/with-refund.component";

export const environment = {
  production: false,
  environmentName: 'uat',
  iFrameExpressServerApiUrl: 'https://capture-api-uat.agentsecure.com.au',
  pollingTime: 1000,
  configIds : {"738d7551-6d8a-4793-a2db-f70c4fff33f2" : DefaultComponent, "738d7551-6d8a-4793-a2db-000000000000" : WithRefundComponent},
  disableAmexOnConfigIds : ["738d7551-6d8a-4793-a2db-f70c4fff33f2", "6e36e9e5d35d19320966ffebc16f03ab"],
  disableDinersOnConfigIds: ["738d7551-6d8a-4793-a2db-f70c4fff33f2","8ffa93d3ec94c6f608c81251ce22045c"],
  isCardholderNameMandatory: true
};
